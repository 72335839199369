/* eslint-disable unicorn/prefer-starts-ends-with */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable array-callback-return */
/* eslint-disable max-lines */
import Components from 'formiojs/components/Components';
import FormioUtils from 'formiojs/utils';
import _ from 'lodash';
import moment from 'moment';
import {
    DEFAULT_FORMAT,
    getDateFormatForLocales,
    getDateFormattedValue,
} from 'src/formio/components/Datetime/utils';

const DatetimeComponent = (Components as any).components.datetime;

class Datetime extends (DatetimeComponent as any) {
    constructor(component: any, options: any, data: any) {
        super(component, options, data);
        const timezone = this.component.timezone || this.options.timezone;
        const time24hr = !_.get(
            this.component,
            'timePicker.showMeridian',
            true,
        );

        // Change the format to map to the settings.
        if (!this.component.enableDate) {
            this.component.format = this.component.format.replace(
                /yyyy-MM-dd /g,
                '',
            );
        }
        if (!this.component.enableTime) {
            this.component.format = this.component.format.replace(
                / hh:mm a$/g,
                '',
            );
        } else if (time24hr) {
            this.component.format = this.component.format.replace(
                /hh:mm a$/g,
                'HH:mm',
            );
        } else {
            this.component.format = this.component.format.replace(
                /HH:mm$/g,
                'hh:mm a',
            );
        }

        let customOptions = this.component.customOptions || {};

        if (typeof customOptions === 'string') {
            try {
                customOptions = JSON.parse(customOptions);
            } catch (err) {
                customOptions = {};
            }
        }

        /* eslint-disable camelcase */
        this.component.widget = {
            type: 'calendar',
            timezone,
            displayInTimezone: _.get(
                this.component,
                'displayInTimezone',
                'viewer',
            ),
            locale: this.options.language,
            useLocaleSettings: _.get(
                this.component,
                'useLocaleSettings',
                false,
            ),
            allowInput: _.get(this.component, 'allowInput', true),
            mode: 'single',
            enableTime: _.get(this.component, 'enableTime', true),
            noCalendar: !_.get(this.component, 'enableDate', true),
            format: !this.component?.format?.includes(DEFAULT_FORMAT)
                ? getDateFormatForLocales(this.component?.enableTime || false)
                      .formioFormat
                : this.component.format,
            hourIncrement: _.get(this.component, 'timePicker.hourStep', 1),
            minuteIncrement: _.get(this.component, 'timePicker.minuteStep', 5),
            time_24hr: time24hr,
            readOnly: this.options.readOnly,
            minDate: _.get(this.component, 'datePicker.minDate'),
            disabledDates: _.get(this.component, 'datePicker.disable'),
            disableWeekends: _.get(
                this.component,
                'datePicker.disableWeekends',
            ),
            disableWeekdays: _.get(
                this.component,
                'datePicker.disableWeekdays',
            ),
            disableFunction: _.get(
                this.component,
                'datePicker.disableFunction',
            ),
            maxDate: _.get(this.component, 'datePicker.maxDate'),
            // ...customOptions, Comment it due it broking timepicker
        };
        /* eslint-enable camelcase */

        // Add the validators date.
        this.validators.push('date');
    }

    setValue(value: any, flags: any) {
        if (!value) return;
        const res = super.setValue(value, flags);

        this.redraw();

        return res;
    }

    attach(element: any) {
        return super.attach(element);
    }

    getValueAsString(value: string) {
        if (this.component?.format?.includes(DEFAULT_FORMAT)) {
            let format = FormioUtils.convertFormatToMoment(
                this.component.format,
            );
            format += format.match(/z$/) ? '' : ' z';
            const { timezone } = this;
            if (value && !this.attached && timezone) {
                return _.trim(
                    FormioUtils.momentDate(value, format, timezone).format(
                        format,
                    ),
                );
            }

            return (value ? _.trim(moment(value).format(format)) : value) || '';
        }

        const isTimeEnabled = this.component?.enableTime;

        return getDateFormattedValue(this.getValue(), isTimeEnabled);
    }

    calculateComponentValue(data: any, flags: any, row: any) {
        // Skip value calculation for the component if we don't have entire form data set or in builder mode
        if (this.builderMode || _.isUndefined(_.get(this, 'root.data'))) {
            return false;
        }
        // If no calculated value or
        // hidden and set to clearOnHide (Don't calculate a value for a hidden field set to clear when hidden)
        const { clearOnHide } = this.component;
        const shouldBeCleared = !this.visible && clearOnHide;
        const allowOverride = _.get(
            this.component,
            'allowCalculateOverride',
            false,
        );

        if (shouldBeCleared) {
            // remove calculated value so that the value is recalculated once component becomes visible
            if (this.hasOwnProperty('calculatedValue') && allowOverride) {
                _.unset(this, 'calculatedValue');
            }

            return false;
        }

        // Handle all cases when calculated values should not fire.
        if (
            (this.options.readOnly &&
                !this.options.pdf &&
                !this.component.calculateValue) ||
            !(
                this.component.calculateValue ||
                this.component.calculateValueVariable
            ) ||
            (this.options.server && !this.component.calculateServer) ||
            (flags.dataSourceInitialLoading && allowOverride)
        ) {
            return false;
        }

        const { dataValue } = this;
        // Calculate the new value.
        let calculatedValue = this.doValueCalculation(
            dataValue,
            data,
            row,
            flags,
        );

        if (!calculatedValue) {
            return false;
        }

        if (this.options.readOnly && dataValue && !calculatedValue) {
            return false;
        }

        if (_.isNil(calculatedValue)) {
            calculatedValue = this.emptyValue;
        }

        const changed = !_.isEqual(dataValue, calculatedValue);

        // Do not override calculations on server if they have calculateServer set.
        if (allowOverride) {
            // The value is considered locked if it is not empty and comes from a submission value.
            const fromSubmission =
                flags.fromSubmission && this.component.persistent === true;
            if (this.isEmpty(dataValue)) {
                // Reset the calculation lock if ever the data is cleared.
                this.calculationLocked = false;
            } else if (this.calculationLocked || fromSubmission) {
                this.calculationLocked = true;

                return false;
            }

            const firstPass =
                this.calculatedValue === undefined || flags.resetValue;
            if (firstPass) {
                this.calculatedValue = null;
            }
            const newCalculatedValue = this.normalizeValue(
                this.convertNumberOrBoolToString(calculatedValue),
            );
            const previousCalculatedValue = this.normalizeValue(
                this.convertNumberOrBoolToString(this.calculatedValue),
            );
            const normalizedDataValue = this.normalizeValue(
                this.convertNumberOrBoolToString(dataValue),
            );
            const calculationChanged = !_.isEqual(
                previousCalculatedValue,
                newCalculatedValue,
            );
            const previousChanged = !_.isEqual(
                normalizedDataValue,
                previousCalculatedValue,
            );

            if (calculationChanged && previousChanged && !firstPass) {
                return false;
            }

            // Check to ensure that the calculated value is different than the previously calculated value.
            if (
                previousCalculatedValue &&
                previousChanged &&
                !calculationChanged
            ) {
                this.calculatedValue = null;

                return false;
            }

            if (flags.isReordered || !calculationChanged) {
                return false;
            }

            if (fromSubmission) {
                // If we set value from submission and it differs from calculated one, set the calculated value to prevent overriding dataValue in the next pass
                this.calculatedValue =
                    FormioUtils.fastCloneDeep(calculatedValue);

                return false;
            }

            // If this is the firstPass, and the dataValue is different than to the calculatedValue.
            if (
                firstPass &&
                !this.isEmpty(dataValue) &&
                changed &&
                calculationChanged
            ) {
                // Return that we have a change so it will perform another pass.
                return true;
            }
        }

        this.calculatedValue = FormioUtils.fastCloneDeep(calculatedValue);

        if (changed) {
            if (!flags.noPristineChangeOnModified) {
                this.pristine = false;
            }

            flags.triggeredComponentId = this.id;

            return this.setValue(calculatedValue, flags);
        }

        return false;
    }
}

export default Datetime;
