/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import { notification } from 'antd';
import axios, { AxiosPromise } from 'axios';
import i18next from 'i18next';
import { action, computed, observable } from 'mobx';

type Unwrap<T> = T extends Promise<infer U>
    ? U
    : T extends AxiosPromise<infer U>
    ? U
    : T;

export type AsyncOperationWithStatusOptions = {
    notifyIfError?: boolean;
};

export type AsyncFunction = (
    ...args: any[]
) => Promise<any> | AxiosPromise<any> | undefined;

const ERRORS_WITHOUT_DISPLAYS = [
    `Entity was updated by another transaction concurrently`,
].map((e) => e?.trim()?.toLowerCase());

const preventErrorDisplay = (error: string) => {
    const formattedError = error?.trim()?.toLowerCase();

    return ERRORS_WITHOUT_DISPLAYS?.some((part) =>
        formattedError.includes(part),
    );
};

export class AsyncOperationWithStatus<
    F extends AsyncFunction,
    DataType = Unwrap<ReturnType<F>>,
> {
    @observable isLoading = false;
    @observable error?: any;
    @observable errorData?: any;
    @observable isLoaded = false;
    @observable data?: DataType;
    @observable countLoads = 0;

    private abortController = new AbortController();
    private cancelTokenSource = axios.CancelToken.source();

    private silentMode = false;

    @computed get hasData() {
        return Boolean(this.data);
    }

    @computed get hasError() {
        return Boolean(this.error);
    }

    constructor(private fn: F) {}

    @action async call(...params: Parameters<F>) {
        try {
            if (!this.silentMode) {
                this.isLoading = true;
            }

            this.abort();

            this.isLoaded = false;
            this.error = undefined;
            this.errorData = undefined;

            const data = (await this.fn.call(undefined, ...params, {
                signal: this.abortController.signal,
                cancelToken: this.cancelTokenSource.token,
            })) as DataType;

            this.setData(data);

            return data;
        } catch (error) {
            console.error(error);
            this.error = error;
            if (axios.isAxiosError(error)) {
                this.errorData = error.response;
                this.showErrorNotifications();
            } else {
                this.errorData = undefined;
            }
            this.isLoaded = false;
        } finally {
            this.countLoads++;
            this.isLoading = false;
        }
    }

    showErrorNotifications() {
        if (isNetworkError(this.errorData) && this.errorData.status >= 400) {
            const errorNotificationTitle =
                this.errorData.status >= 500
                    ? i18next.t('Errors.500')
                    : i18next.t('Error');
            const errorDescriptions = this.errorData?.violations || [];
            const hasErrorDescriptions = errorDescriptions?.length > 0;

            const errorsFromDescriptions = errorDescriptions
                ?.map((e) => e.message)
                ?.join(' ');

            const errorText =
                errorsFromDescriptions ||
                this.errorData?.detail ||
                this.errorData.title ||
                '';

            const isErrorDisplayShouldPrevented =
                preventErrorDisplay(errorText);

            if (isErrorDisplayShouldPrevented) {
                return;
            }

            if (hasErrorDescriptions) {
                errorDescriptions.forEach((error) => {
                    notification.error({
                        message: errorNotificationTitle,
                        description: i18next.t(error.message),
                    });
                });
            } else {
                notification.error({
                    message: errorNotificationTitle,
                    description: this.errorData?.detail || this.errorData.title,
                });
            }
        }
    }

    @action setData(data: DataType) {
        this.data = data;
        this.isLoaded = true;
    }

    @action abort() {
        this.abortController.abort();
        this.cancelTokenSource.cancel();

        this.abortController = new AbortController();
        this.cancelTokenSource = axios.CancelToken.source();
    }

    @action reset() {
        this.abort();

        this.error = undefined;
        this.data = undefined;
        this.isLoading = false;
    }

    turnOnSilentMode() {
        this.silentMode = true;
    }

    turnOffSilentMode() {
        this.silentMode = false;
    }
}

interface NetworkError {
    detail?: string;
    status: number;
    title?: string;
    violations?: {
        field: string;
        message: string;
    }[];
}
function isNetworkError(value: any): value is NetworkError {
    if (typeof value?.status === 'number') {
        return true;
    }

    return false;
}
