/* eslint-disable no-underscore-dangle */
import Keycloak from 'keycloak-js';
import { action, computed, observable } from 'mobx';
import { AppEnv } from 'src/core/Environment';
import { UiConfigurations } from 'src/generated-api-client';
import i18next, { initInternationalization } from 'src/i18n';
import { uiSettingsApi } from 'src/services/apiServices';
import { CookieHelper } from 'src/utils/CookieHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;
const KEYCLOAK_LOCALE = 'KEYCLOAK_LOCALE';
const TIME_TO_REFRESH_TOKEN_MS = 1000 * 20;

export class SettingsStoreClass {
    settingsLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(uiSettingsApi.getSettings()),
    );

    @observable currentLocale?: string;
    @observable currencyMnemonic?: string;
    intervalId?: number;

    @action async load() {
        const resp = await this.settingsLoader.call();

        if (!this.settingsLoader.hasError) {
            this.currencyMnemonic = resp?.find(
                (config) => config.appName === AppEnv.appName,
            )?.env?.currencyMnemonic;
        }
    }

    @computed get settings(): UiConfigurations | undefined {
        return this.settingsLoader.data?.find(
            (config) => config.appName === AppEnv.appName,
        );
    }

    @computed get hasSettings() {
        return this.settingsLoader.hasData;
    }

    @action setupKeycloak() {
        this._keycloack = Keycloak({
            realm: this.settings?.authRealm!,
            clientId: AppEnv.appName, // resource
            url: this.settings?.authServerUrl, // auth-server-url
        });

        this._keycloack.onTokenExpired = () => {
            this.updateTokenIfNeed();
        };
    }

    @action setupBrowserBehaviorWithKeycloak() {
        window.addEventListener('focus', () => {
            this.startIntervalToCheckAuthentication();
        });
        window.addEventListener('blur', () => {
            this.stopIntervalToCheckAuthentication();
        });
        window.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                this.stopIntervalToCheckAuthentication();
            } else {
                this.startIntervalToCheckAuthentication();
            }
        });

        window.addEventListener('offline', () => {
            this.stopIntervalToCheckAuthentication();
        });

        window.addEventListener('online', () => {
            this.startIntervalToCheckAuthentication();
        });

        window.addEventListener('load', () => {
            this.startIntervalToCheckAuthentication();
        });
    }

    @action async startIntervalToCheckAuthentication() {
        if (this.intervalId) {
            return;
        }

        this.stopIntervalToCheckAuthentication();

        await this.updateTokenIfNeed();

        this.intervalId = setInterval(() => {
            this.updateTokenIfNeed();
        }, TIME_TO_REFRESH_TOKEN_MS) as unknown as number;
    }

    @action stopIntervalToCheckAuthentication() {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
    }

    @action async updateTokenIfNeed() {
        try {
            await this.keycloak?.updateToken(UPDATE_TOKEN_MIN_VALIDITY);
        } catch (error) {
            window.location.reload();
        }
    }

    @observable _keycloack?: Keycloak.KeycloakInstance;

    @computed get keycloak() {
        // eslint-disable-next-line no-underscore-dangle

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._keycloack!;
    }

    @action async setupInternationalization() {
        await initInternationalization({
            ...this.settings,
            defaultLocale:
                CookieHelper.getItem(KEYCLOAK_LOCALE) ||
                this.settings?.defaultLocale,
        } as any as any);
    }

    @computed get supportedLocales() {
        return this.settings?.supportedLocales || [];
    }

    @computed get hasFewLocales() {
        return this.supportedLocales.length > 1;
    }

    @action async setLocale(locale: string) {
        CookieHelper.setItem(KEYCLOAK_LOCALE, locale);
        await i18next.changeLanguage(locale);
    }

    @action async setup() {
        await this.setupInternationalization();
        await this.setupKeycloak();
        await this.setupBrowserBehaviorWithKeycloak();
    }
}

export const SettingsStore = new SettingsStoreClass();
