import _ from 'lodash';
import { action, autorun, computed, observable } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { taskManagerApi } from 'src/services/apiServices';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { TaskActionTypes } from 'src/stores/TaskWithActionStore/TaskActionTypes.types';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class TaskWithActionStoreClass extends BasicStore<Record<string, any>> {
    @observable taskWithActionDefinition?: TaskRepresentation;

    constructor() {
        super();

        this.setTaskWithActionRunner();
    }

    api: BasicStoreApi<Record<string, any>> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.listAssigned(options),
            );

            return resp.content || [];
        },

        loadItem: (id: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.getTaskFormVariables({ id }, options),
            );
        },

        loadItemForm: (id: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.getTaskForm({ id }, options),
            );
        },
    };

    private setTaskWithActionRunner() {
        autorun(() => {
            const taskWithAction = this.findTaskWithAction();
            if (taskWithAction?.id && !this.hasTaskWithAction) {
                this.setTaskWithAction(taskWithAction);
            }
        });
    }

    getTaskById(id: string) {
        return this.list?.find((task) => task.id === id);
    }

    @action async loadTaskDefinitionItem(id: string) {
        const task = this.getTaskById(id);
        if (!task) {
            await this.loadList();
        }
    }

    @action setTaskWithAction(task: TaskRepresentation) {
        this.taskWithActionDefinition = task;
    }

    @observable findTaskWithAction() {
        return AssignedTasksStore.getAssignedList()?.find((task) => {
            return _.has(task?.extensions, 'action');
        });
    }

    @computed get hasTaskWithAction() {
        return Boolean(this.taskWithActionDefinition);
    }

    @computed get taskWithAction() {
        return (
            this.taskWithActionDefinition?.extensions as unknown as {
                action: TaskActionTypes;
            }
        )?.action !== TaskActionTypes.NOTIFICATION
            ? this.taskWithActionDefinition
            : null;
    }

    @computed get taskWithNotificationAction() {
        return (
            this.taskWithActionDefinition?.extensions as unknown as {
                action: TaskActionTypes;
            }
        )?.action === TaskActionTypes.NOTIFICATION
            ? this.taskWithActionDefinition
            : null;
    }

    submitFormLoader = new AsyncOperationWithStatus(
        (id: string, values: Record<string, any>) =>
            RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.submitForm({ id, requestBody: values }),
            ),
    );

    @action reset() {
        this.itemLoader.reset();
        this.formLoader.reset();
        this.listLoader.reset();
        this.taskWithActionDefinition = undefined;
    }

    @action submitForm(taskId: string, values: any) {
        return this.submitFormLoader.call(taskId, values);
    }
}

export const TaskWithActionStore = new TaskWithActionStoreClass();
